import React from 'react'
import HomePage from './HomePage'
import StorySection from './StorySection'
import Body from './Body'
import Body1 from './Body1'
import Aboutus from './Aboutus'
import Gift from './Gift'

function Home() {
  return (
    <div>
      <HomePage/>
      <Aboutus/>
      <Body1/>
      <Gift/>
      <Body/>
      {/* <StorySection/> */}
    </div>
  )
}

export default Home