import React, { useState } from "react";
import { motion } from 'framer-motion';
import bgimage from '../img/canva-cream-light-romantic-wedding-heart-photo-collage-O3yAm2zAZgk.jpg';
import Necklace from '../img/105A0633.png';

function Body() {
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
  const [isHovering, setIsHovering] = useState(false);
  const [scale, setScale] = useState(1);

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setZoomPosition({ x, y });

    // Set the zoom level based on mouse position
    const zoomFactor = 1 + (x / rect.width) * 0.4; // Zoom effect based on mouse position
    setScale(zoomFactor);
  };

  return (
    <div className="flex flex-col lg:flex-row items-center justify-center bg-[#F8F7F1] py-16"
    style={{ fontFamily: 'Kanit, sans-serif' }}
    >
      
      {/* Animation for Background Image */}
      <motion.div
        className="relative w-full lg:w-1/3 h-[300px] lg:h-[500px] overflow-hidden bg-cover bg-center"
        style={{ backgroundImage: `url(${bgimage})` }}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-black opacity-75"></div>

        {/* Necklace Image with Hover and Mouse Move Effects */}
        <motion.img
          onMouseMove={handleMouseMove}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          src={Necklace}
          alt="Necklace"
          className="absolute bottom-4 left-28 transform -translate-x-1/2 w-36 lg:w-96 h-full object-cover mx-auto"
          initial={{ scale: 0 }}
          whileInView={{ scale: 1 }}
          transition={{ duration: 1, type: 'spring', stiffness: 80 }}
          style={{
            transform: `scale(${scale})`,
            transition: "transform 0.2s ease-out", // Smooth transition for zoom effect
          }}
        />

        {/* Zoom Marker when Hovering */}
        {isHovering && (
          <div
            className="absolute w-32 h-32 border-2 border-gray-500 bg-white bg-opacity-50 pointer-events-none rounded-full animate-pulse"
            style={{
              top: `${zoomPosition.y - 48}px`, // Positioning marker based on mouse coordinates
              left: `${zoomPosition.x - 48}px`,
            }}
          />
        )}
      </motion.div>

      {/* Animation for Text and Button */}
      <motion.div
        className="w-full lg:w-1/2 px-6 mt-8 lg:mt-0 text-center lg:text-left rounded-l-full ml-20"
        initial={{ x: -100, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 1, type: 'spring', stiffness: 80 }}
      >
        <h6 className="text-[#7F2E2B] font-semibold text-xl uppercase tracking-wider">#Necklace</h6>
        <motion.h1
          className="text-4xl font-extrabold text-[#7F2E2B] mt-4 mb-6"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Gold Jewellery Collection
        </motion.h1>
        <motion.p
          className="text-lg text-gray-700 mb-8"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          It is beautifully designed in a very smart way to bring the best user experience that you will love.
        </motion.p>
        <motion.button
          className="bg-[#7F2E2B] text-white py-3 px-8 rounded-full text-lg font-semibold hover:bg-[#5E1D1A] transition duration-300"
          whileInView={{ scale: 1.1 }}
          transition={{ type: 'spring', stiffness: 100 }}
        >
          Our Store Location
        </motion.button>
      </motion.div>

    </div>
  );
}

export default Body;
