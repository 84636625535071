import React from "react";
import { motion } from "framer-motion";
import video from "../img/AboutVideo.mp4";
import { FaGem, FaShieldAlt, FaHandshake, FaUsers } from "react-icons/fa";

function Aboutus() {
  return (
    <motion.div
      className="py-28"
      style={{ fontFamily: "Kanit, sans-serif" }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      {/* Welcome Section */}
      <motion.div
        className="container mx-auto text-center mb-12"
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, type: "spring", stiffness: 70 }}
      >
        <h1
          className="text-7xl font-serif font-bold text-[#7F2E2B] leading-tight"
          style={{ fontFamily: "Ruthie, cursive" }}
        >
          Welcome
          <span className="block w-32 h-1 bg-[#7F2E2B] mx-auto"></span>
        </h1>
        <h2 className="text-3xl text-gray-800 font-semibold mt-4 font-Kanit">
          To The World of Phanasgaonkar Bandhu Jewellers
        </h2>
      </motion.div>

      {/* About Us Content */}
      <motion.div
        className="max-w-7xl mx-auto text-lg text-gray-700 px-4 font-Kanit"
        initial={{ opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 1, type: "spring", stiffness: 80 }}
      >
        <p>
          Phanasgaonkar Bandhu Jewellers was established over 50 years ago by
          our late father, Shri Vishnu Sakharam Phanasgaonkar, in a small shop
          in Mumbai. He began by crafting jewelry by hand, and as the business
          grew, he was joined by skilled craftsmen to expand.
        </p>
        <p className="mt-4">
          After his untimely passing, his three sons—Sudhakar, Anil, and
          Sanjay—took charge, naming the business "Phanasgaonkar Bandhu
          Jewellers." Today, our legacy continues with the love and trust of our
          customers.
        </p>
        <p className="mt-4">
          Although the three brothers now run their own shops, the legacy of
          Phanasgaonkar Bandhu Jewellers is carried forward by Shri Sanjay V.
          Phanasgaonkar, his wife Smt. Manisha S. Phanasgaonkar, and their
          children Priyanka and Gautam, who uphold the family’s commitment to
          quality and service.
        </p>
      </motion.div>

      {/* Video and Values Section */}
      <div className="container px-20 mx-auto flex flex-col md:flex-row gap-12 mt-12">
        {/* Video Section */}
        <motion.div
          className="max-w-full md:w-1/2 bg-white px-20 py-10"
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, type: "spring", stiffness: 80 }}
        >
          <video
            src={video}
            controls
            className="w-full h-auto max-h-96 object-cover rounded-lg"
            title="About Video"
          />
        </motion.div>

        {/* Values Section */}
        <motion.div
          className="md:w-1/2 text-left"
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, type: "spring", stiffness: 80 }}
        >
          <h2 className="text-3xl text-gray-800 font-semibold mb-6 font-Kanit">
            Our Values
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-8 font-Kanit">
            {/* Value Items */}
            {[
              {
                icon: <FaGem className="text-4xl text-[#7F2E2B] mb-4" />,
                title: "Craftsmanship",
                text:
                  "We value the skill of our craftsmen who create beautiful, timeless jewelry.",
              },
              {
                icon: <FaShieldAlt className="text-4xl text-[#7F2E2B] mb-4" />,
                title: "Quality",
                text:
                  "We focus on delivering high-quality jewelry that lasts and looks stunning.",
              },
              {
                icon: <FaHandshake className="text-4xl text-[#7F2E2B] mb-4" />,
                title: "Customer Trust",
                text:
                  "Our success comes from the trust of our customers, and we aim to always provide great service.",
              },
              {
                icon: <FaUsers className="text-4xl text-[#7F2E2B] mb-4" />,
                title: "Family Legacy",
                text:
                  "As a family business, we continue the tradition started by our founder, Shri Vishnu Sakharam Phanasgaonkar, serving with honesty and pride.",
              },
            ].map((value, index) => (
              <motion.div
                key={index}
                className="flex flex-col text-left max-w-full"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ delay: index * 0.2, duration: 0.8 }}
              >
                <div className="flex gap-3">
                  {value.icon}
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">
                    {value.title}
                  </h3>
                </div>
                <p className="text-gray-600">{value.text}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
}

export default Aboutus;
