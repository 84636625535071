import React, { useState } from "react";
import { IoMdCall, IoLogoWhatsapp } from "react-icons/io";
import { ImLocation } from "react-icons/im";
import { FaInstagramSquare, FaFacebook, FaLinkedin } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import contact from "../img/Karpagam-Jewellers-banner.jpg";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!name || !email || !subject || !message) {
      alert("Please fill in all fields.");
      return;
    }

    const whatsappMessage = `Name: ${name}\nEmail: ${email}\nSubject: ${subject}\nMessage: ${message}`;
    const whatsappLink = `https://api.whatsapp.com/send?phone=919881257256&text=${encodeURIComponent(
      whatsappMessage
    )}`;
    window.open(whatsappLink, "_blank");

    setName("");
    setEmail("");
    setSubject("");
    setMessage("");
  };

  return (
    <div className="font-kanit" style={{ fontFamily: "Kanit, sans-serif" }}>
      <div className="relative">
        <img
          src={contact}
          alt="Contact Us"
          className="w-full h-auto object-cover"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center bg-black/50 text-white pt-48">
          <h1 className="text-5xl font-bold">Contact Us</h1>
          <span className="block w-32 h-1 bg-white mt-2"></span>
        </div>
      </div>

      {/* Contact Form and Details */}
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="grid md:grid-cols-2 gap-8">
          {/* Form Section */}
          <form
            className="space-y-6 bg-white p-8 shadow-lg rounded-lg"
            onSubmit={handleSubmit}
          >
            <h1 className="text-3xl font-bold text-[#881519]">Enquire Now</h1>
            <input
              type="text"
              placeholder="Name"
              className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm outline-none focus:ring-2 focus:ring-[#881519]"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              type="email"
              placeholder="Email"
              className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm outline-none focus:ring-2 focus:ring-[#881519]"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Subject"
              className="w-full rounded-md py-3 px-4 bg-gray-100 text-sm outline-none focus:ring-2 focus:ring-[#881519]"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
            <textarea
              placeholder="Message"
              rows="6"
              className="w-full rounded-md px-4 py-3 bg-gray-100 text-sm outline-none focus:ring-2 focus:ring-[#881519]"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
            <button
              type="submit"
              className="w-full py-3 text-white bg-[#881519] hover:bg-yellow-800 rounded-md font-semibold text-sm transition"
            >
              Send
            </button>
          </form>

          {/* Contact Details Section */}
          <div className="space-y-8">
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              <ContactCard
                icon={<IoMdCall />}
                label="Call"
                value="+91 9881257256"
              />
              <ContactCard
                icon={<IoLogoWhatsapp />}
                label="WhatsApp"
                value="+91 9881257256"
              />
            </div>
            <div className="space-y-6">
              <ContactCard
                icon={<ImLocation />}
                label="Address"
                value="Flat No 103 1st Floor Shreeram Prestige, High School Road Wakharbhag Sangli, Sangli Ho, Sangli - 416416"
              />
              <ContactCard
                icon={<MdEmail />}
                label="Email"
                value="mhrishikesh10@gmail.com"
              />
            </div>
            <div className="grid grid-cols-2 gap-6 md:grid-cols-4">
              <SocialCard
                icon={<FaFacebook />}
                label="Facebook"
                link="https://www.facebook.com/chaitanya.kulkarni.338"
              />
              <SocialCard
                icon={<FaInstagramSquare />}
                label="Instagram"
                link="#"
              />
              <SocialCard icon={<FaLinkedin />} label="LinkedIn" link="#" />
              <SocialCard
                icon={<IoLogoWhatsapp />}
                label="WhatsApp"
                link="https://api.whatsapp.com/send?phone=919881257256"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Google Map Section */}
      <div className="w-full h-64 md:h-96 mt-8">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6313.29344745018!2d72.83191596961892!3d19.003726478117127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cfbd118e8b9f%3A0x149a9bb418f777bc!2sPhanasgaonkar%20Bandhu%20Jewellers!5e0!3m2!1sen!2sin!4v1727953491584!5m2!1sen!2sin"
          className="w-full h-full border-0"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Location Map"
        ></iframe>
      </div>
    </div>
  );
}

function ContactCard({ icon, label, value }) {
  return (
    <div className="flex items-start space-x-4 p-6 bg-white shadow-md rounded-md">
      <div className="p-4 bg-[#F9ECE3] rounded-full text-[#881519] text-xl">
        {icon}
      </div>
      <div>
        <p className="text-lg font-semibold text-gray-800">{label}</p>
        <p className="text-sm text-gray-600">{value}</p>
      </div>
    </div>
  );
}

function SocialCard({ icon, label, link }) {
  return (
    <a
      href={link}
      className="flex flex-col items-center space-y-2 p-4 bg-white shadow-md rounded-md hover:bg-yellow-100 transition"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="p-4 bg-[#F9ECE3] rounded-full text-[#881519] text-xl">
        {icon}
      </div>
      <p className="text-sm font-semibold text-gray-800">{label}</p>
    </a>
  );
}

export default Contact;
