import React from "react";
import { motion } from "framer-motion";
import img1 from "../img/engagement.jpeg";
import img2 from "../img/birthday.JPG";
import img3 from "../img/wending.JPG";
import img4 from "../img/kids.JPG";
import img5 from "../img/aniversary.JPG";

function Gift() {
  const gifts = [
    { img: img1, head: "Engagement" },
    { img: img2, head: "Birthday" },
    { img: img3, head: "Wedding" },
    { img: img4, head: "Small Wonders" },
    { img: img5, head: "Anniversary" },
  ];

  return (
    <div className="container mx-auto py-28 px-4 sm:px-6 lg:px-8 mb-16">
      {/* Heading */}
      <motion.h1
        className="text-5xl text-center font-bold text-[#7F2E2B] mb-12"
        style={{ fontFamily: "Ruthie, cursive" }}
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, type: "spring", stiffness: 70 }}
      >
        A Perfect Gift
        <span className="block w-32 h-1 bg-[#7F2E2B] mx-auto mt-2"></span>
      </motion.h1>

      {/* Gift Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8">
        {gifts.map((item, index) => (
          <motion.div
            key={index}
            className="group relative cursor-pointer bg-white rounded-lg overflow-hidden"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.8,
              type: "spring",
              stiffness: 80,
              delay: index * 0.2,
            }}
          >
            {/* Image */}
            <img
              src={item.img}
              alt={item.head}
              className="w-full h-56 object-cover transform group-hover:scale-105 transition duration-500"
            />

            {/* Title */}
            <div className="p-4 text-center">
              <h2 className="text-xl font-bold text-[#7F2E2B]">{item.head}</h2>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default Gift;
