import React from "react";
import logo from "../img/pbj-logo.png";
import bgimage from "../img/service-bg-1.png";

const Footer = () => {
  return (
    <footer
      className="bg-[#7F2E2B] text-gray-200 py-10 px-6"
      style={{
        backgroundImage: `url(${bgimage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundBlendMode: "overlay",
        fontFamily: 'Kanit, sans-serif'
      }}

    >
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Logo and Business Info */}
        <div className="text-center md:text-left">
          <img
            src={logo}
            alt="PBJ Logo"
            className="mx-auto md:mx-auto w-16 h-16"
          />
          <h3 className="mt-4 text-xl font-bold text-white">
            Phanasgaonkar Bandhu Jewellers
          </h3>
        </div>

        {/* About Us Section */}
        <div>
          <h4 className="text-lg font-semibold text-white mb-4">About Us</h4>
          <p className="text-sm">
            Phanasgaonkar Bandhu Jewellers started their goldsmith business in
            1960 under the name “Phanasgaonkar Bandhu Jewellers.”
          </p>
        </div>

        {/* Contact Section */}
        <div>
          <h4 className="text-lg font-semibold text-white mb-4">Contact Us</h4>
          <p className="text-sm">
            12/13, Ground Floor, Madhav Bhuvan, J.M. Street, <br />
            Near Bachooali Hospital, Parel East, <br />
            Mumbai, Maharashtra 400012 <br />
            <strong>Phone:</strong> +91 8879011532, +91 9930025989 <br />
            <strong>Email:</strong> info@pbjjewellers.com <br />
          </p>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className="border-t border-gray-700 mt-10 pt-6 text-center">
        <p className="text-sm">
          © All Rights Reserved.{" "}
          <span>Website Designed And Developed</span>{" "}
          by{" "}
          <a
            className="text-cyan-400 hover:text-cyan-300 transition cursor-pointer "
            href="https://www.businessmantra.info/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Business Mantra
          </a>
          .
        </p>
      </div>
    </footer>
  );
};

export default Footer;
