import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Component/Navbar/Navbar';
import Contact from './Component/Contact/Contact';
import About from './Component/About/About';
import Home from './Component/Home/Home';
import Footer from './Component/Contact/Footer';
import Products from './Component/Product/Products.Jsx';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <div className="flex-1">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="products" element={<Products />} />

          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
