import React, { useState, useEffect } from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import logo from "../img/pbj-logo.png"; // Initial logo
import mainlogo from "../img/pbj_logo_3.png"; // Secondary logo

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header
      className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${
        isScrolled ? "bg-transparent  py-3" : "bg-transparent py-5"
      }`}
      style={{ fontFamily: "Kanit, sans-serif" }}
    >
      {/* Main Navbar */}
      <div className="container mx-auto px-4 flex items-center justify-between h-16">
        {/* Left: Logo */}
        <div>
          <Link to="/">
            <img
              src={logo} // Use only the `logo` image
              alt="Company Logo"
              className={`h-16 transition-all duration-300 ${
                isScrolled ? "scale-110" : "scale-100"
              }`}
            />
          </Link>
        </div>

        {/* Right: Navigation Menu */}
        {!isScrolled && (
          <nav className="hidden lg:flex items-center space-x-6">
            <Link
              to="/"
              className={`text-gray-100 font-medium ${
                location.pathname === "/"
                  ? "text-yellow-500 font-bold"
                  : "hover:text-yellow-500"
              } transition duration-300`}
            >
              Home
            </Link>
            <Link
              to="/products"
              className={`text-gray-100 font-medium ${
                location.pathname === "/products"
                  ? "text-yellow-500 font-bold"
                  : "hover:text-yellow-500"
              } transition duration-300`}
            >
              Products
            </Link>
            <Link
              to="/about"
              className={`text-gray-100 font-medium ${
                location.pathname === "/about"
                  ? "text-yellow-500 font-bold"
                  : "hover:text-yellow-500"
              } transition duration-300`}
            >
              About Us
            </Link>
            <Link
              to="/contact"
              className={`text-gray-100 font-medium ${
                location.pathname === "/contact"
                  ? "text-yellow-500 font-bold"
                  : "hover:text-yellow-500"
              } transition duration-300`}
            >
              Contact Us
            </Link>
          </nav>
        )}

        {/* Social Media Icons */}
        {!isScrolled && (
          <div className="hidden lg:flex items-center space-x-4">
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-100 hover:text-blue-500 transition duration-300"
            >
              <FaFacebookF size={20} />
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-100 hover:text-blue-400 transition duration-300"
            >
              <FaTwitter size={20} />
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-100 hover:text-pink-500 transition duration-300"
            >
              <FaInstagram size={20} />
            </a>
            <a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-100 hover:text-blue-700 transition duration-300"
            >
              <FaLinkedinIn size={20} />
            </a>
          </div>
        )}

        {/* Hamburger Menu (Mobile) */}
        <div className="lg:hidden">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="text-gray-800 hover:text-yellow-500 transition duration-300"
            aria-label="Toggle navigation menu"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Secondary Navbar */}
      {isScrolled && (
        <div className="fixed top-0 left-0 w-full p-2  bg-white shadow-md">
          <div className="container mx-auto px-4 py-2 flex justify-between items-center">
            {/* Logo */}
            <div>
              <img src={mainlogo} alt="Main Logo" className="w-96 h-auto" />
            </div>

            {/* Menu */}
            <nav className="flex items-center space-x-4">
              <Link
                to="/"
                className={`text-gray-800 text-sm font-medium ${
                  location.pathname === "/"
                    ? "text-yellow-500 font-bold"
                    : "hover:text-yellow-500"
                } transition duration-300`}
              >
                Home
              </Link>
              <Link
                to="/products"
                className={`text-gray-800 text-sm font-medium ${
                  location.pathname === "/products"
                    ? "text-yellow-500 font-bold"
                    : "hover:text-yellow-500"
                } transition duration-300`}
              >
                Products
              </Link>
              <Link
                to="/about"
                className={`text-gray-800 text-sm font-medium ${
                  location.pathname === "/about"
                    ? "text-yellow-500 font-bold"
                    : "hover:text-yellow-500"
                } transition duration-300`}
              >
                About Us
              </Link>
              <Link
                to="/contact"
                className={`text-gray-800 text-sm font-medium ${
                  location.pathname === "/contact"
                    ? "text-yellow-500 font-bold"
                    : "hover:text-yellow-500"
                } transition duration-300`}
              >
                Contact Us
              </Link>
            </nav>
          </div>
        </div>
      )}
    </header>
  );
};

export default Navbar;
