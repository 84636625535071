import React from "react";
import { motion } from "framer-motion";
import men from "../img/Men_chain.JPG";
import woman from "../img/woman_neclace.JPG";

function Body1() {
  return (
    <div className="py-16 px-8"
    style={{ fontFamily: 'Kanit, sans-serif' }}>
      {/* Animated Intro Text */}
      <motion.div
        className="text-center mb-10"
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, type: "spring", stiffness: 70 }}
      >
        <h1 className="text-7xl font-serif font-bold text-[#7F2E2B] leading-tight"
        style={{ fontFamily: 'Ruthie, cursive' }}
        >
          Step into the world of everyday beauty
          <span className="block w-32 h-1 bg-[#7F2E2B]  mx-auto"></span>

        </h1>
        <motion.p
          className="text-xl text-gray-600 mt-4"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
        >
          Discover timeless elegance and modern sophistication
        </motion.p>
      </motion.div>

      {/* Grid Layout */}
      <div className="grid md:grid-cols-2 gap-14 mt-16">
         {/* Section 2: Women's Necklace */}
         <motion.div
          className="flex flex-col items-center md:items-end"
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, type: "spring", stiffness: 80 }}
        >
          <motion.img
            src={woman}
            alt="Elegant necklace for women"
            className="w-3/4 h-auto object-cover rounded-tr-full rounded-bl-full mb-6 border-b-4 border-black shadow-lg"
            initial={{ scale: 0 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 80 }}
          />
          <div className="text-center md:text-right">
            <h2 className="text-2xl font-semibold text-[#7F2E2B] mb-2">
              Elegance, Redefined
            </h2>
            <p className="text-lg text-gray-600">
              Jewels that capture your essence, radiating beauty with every glance
            </p>
          </div>
        </motion.div>
        {/* Section 1: Men's Chain */}
        <motion.div
          className="flex flex-col items-center md:items-start"
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, type: "spring", stiffness: 80 }}
        >
          <div className="text-center md:text-left">
            <h2 className="text-2xl font-semibold text-[#7F2E2B] mb-2">
              Crafted for the Modern Man
            </h2>
            <p className="text-lg text-gray-600">
              A chain that speaks volumes about your strength and style
            </p>
          </div>
          <motion.img
            src={men}
            alt="Gold chain for men"
            className="w-3/4 h-auto object-cover rounded-tl-full rounded-br-full mt-6 border-b-4 border-black shadow-lg"
            initial={{ scale: 0 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 80 }}
          />
        </motion.div>

       
      </div>
    </div>
  );
}

export default Body1;
