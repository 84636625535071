import React from "react";
import about from "../img/Karpagam-Jewellers-banner.jpg";

function About() {
  return (
    <div className="font-kanit bg-gray-50">
      {/* Banner Section */}
      <div className="relative">
        <img
          src={about}
          alt="About Us"
          className="w-full h-auto object-cover"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center bg-black/50 text-white pt-48">
          <h1 className="text-5xl font-bold">About Us</h1>
          <span className="block w-32 h-1 bg-white mt-2"></span>
        </div>
      </div>

      {/* About Section */}
      <div className="max-w-7xl mx-auto px-4 py-12 space-y-12">
        {/* History Section */}
        <div>
          <h2
            className="text-7xl font-bold text-center text-[#7F2E2B]"
            style={{ fontFamily: "Ruthie, cursive" }}
          >
            History
          </h2>
          <span className="block w-32 h-1 bg-[#7F2E2B] mx-auto mt-4"></span>
        </div>
        <div className="space-y-6 text-gray-700 text-lg leading-relaxed">
          <p>
            Under the name{" "}
            <strong className="text-[#7F2E2B]">
              "Phanasgaonkar Bandhu Jewellers,"
            </strong>{" "}
            the business we currently run was originally started by my late
            father, Shri Vishnu Sakharam Phanasgaonkar, in a small shop at
            Mumbai's Elphinstone (now Prabhadevi). He handcrafted all the
            jewelry himself in the beginning. As the business grew, he hired
            two craftsmen to help and successfully expanded the enterprise.
          </p>
          <p>
            After his sudden demise, the three brothers—Sudhakar, Anil, and
            Sanjay—worked together to further develop the business and formally
            named it{" "}
            <strong className="text-[#7F2E2B]">
              "Phanasgaonkar Bandhu Jewellers."
            </strong>{" "}
            Over the past 50 to 60 years, the business has earned a strong
            reputation in Mumbai. Thanks to the love and trust of our countless
            customers, the business has flourished greatly.
          </p>
          <p>
            In recent years, the three brothers have established separate
            shops. However, the ownership and operation of the original{" "}
            <strong className="text-[#7F2E2B]">
              "Phanasgaonkar Bandhu Jewellers"
            </strong>{" "}
            remain with Shri Sanjay V. Phanasgaonkar, his wife Smt. Manisha S.
            Phanasgaonkar, his daughter Priyanka S. Phanasgaonkar, and his son
            Gautam S. Phanasgaonkar, who continue to run the business together.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
